<template>
  <main class="kb-main" id="kb-hottip-board">
    <HotTipHeader />
    <template v-if="boardMst">
      <div class="hottip-banner">
        <div v-if="!isMobile" class="file-link">
          <FileDownLink fileKey="HotTipGuide" :addTextClass="['text','text-white','align-text-top']" iconClass="icon-arrow20-right-w" />
        </div>
        <div class="banner-img">
          <swiper class="hrdcloud-swiper h-100"
                  :modules="modules"
                  effect="fade"
                  :pagination="{
                      el: '.swiper-pagination',
                      clickable: true
                  }"
                  :autoplay="{
                      delay: 30000,
                      disableOnInteraction: true,
                      stopOnLast: true,
                  }"
                  :navigation="{
                      prevEl: '.swiper-btn-prev',
                      nextEl: '.swiper-btn-next'
                  }"
          >
            <swiper-slide v-for="(item, index) in banners" :key="index" @click.stop="goToBannerUrl(item)">
              <HrdCloudBannerVideo v-if="item.type === 'video'" :video-src="isMobile ? item.mobiThumb : item.thumb" />
              <CommonImage v-else :img-src="isMobile ? item.mobiThumb : item.thumb" />
            </swiper-slide>
            <div class="swiper-pagination"></div>
            <div v-if="!isMobile" class="swiper-buttons">
              <div class="swiper-btn-prev"><i class="icon-arrow" /></div>
              <div class="swiper-btn-next"><i class="icon-arrow" /></div>
            </div>
          </swiper>
        </div>
        <span v-if="showBoardList && banners.length == 0" class="banner-title">{{boardMst.boardTitle}}</span>
      </div>
      <div class="main-content main-component">
        <template v-if="isMobile">
          <!-- 카테고리 -->
          <div class="header-snb">
            <nav class="snb mt-1">
              <ul class="snb-list">
                <template v-if="boardMst.boardId != 'hottip-notice'">
                  <li v-if="boardMst.boardId != 'hottip-hq'" class="snb-item" :class="{'is-active' : hotTipBest}">
                    <button @click="hotTipBest ? search('postBadgeCdDcd','') : search('postBadgeCdDcd',HotTipBoardBadgeCdDcd.BEST)">우수 Tip</button>
                  </li>
                  <li class="snb-item" :class="{'is-active' : hotTipLike}">
                    <button @click="hotTipLike ? search('isLike','') : search('isLike','Y')">좋아요한 Tip</button>
                  </li>
                </template>
                <template v-if="boardMst.srchCateYn === 'Y'">
                  <li v-for="(item,idx) in boardCateList" :key="item.boardCateSn" class="snb-item" :class="{'is-active' : activeBoardCateSn === Number(item.boardCateSn), 'snb-item-divider' : idx == 0}">
                    <button @click="search('boardCateSn',item.boardCateSn)">{{ item.cateNm }}</button>
                  </li>
                </template>
              </ul>
            </nav>
          </div>
          <div class="list-top status"><div class="top-column"><p class="text text-muted">총 {{listTotalCount}}건</p></div></div>
          <BoardMainListMobile v-if="showBoardList" :board-mst-map="boardMst" :board-cate-list="boardCateList" v-model:list-total-count="listTotalCount" />
          <div class="visitor-info">
            <span class="text text-center" v-html="hotTipManagerInfo"></span>
          </div>
        </template>
        <template v-else>
          <boardMainList v-if="showBoardList" :board-mst-map="boardMst" :board-cate-list="boardCateList" />
        </template>
      </div>
    </template>
  </main>
</template>

<script>
import {computed, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {Autoplay, Pagination} from 'swiper';
import HotTipHeader from "@/components/prep/HotTipHeader.vue";
import boardMainList from '@/components/board/BoardMainList';
import BoardMainListMobile from '@/components/board/mobile/BoardMainList.vue';
import {
  getHotTipBoardCateList,
  getHotTipBoardMst, HotTipBoardBadgeCdDcd,
  hotTipManagerInfo,
} from "@/assets/js/modules/learn/learn-hot-tip";
import {banerDivCdDcds, banerExpsrLocCdDcds, getBanerList} from "@/assets/js/modules/baner/common-baner";
import HrdCloudBannerVideo from "@/components/hrdcloud/HrdCloudBannerVideo.vue";
import CommonImage from "@/components/common/CommonImage.vue";
import FileDownLink from "@/components/common/FileDownLink.vue";

export default {
  name: 'HotTipBoard',
  components: {
    FileDownLink,
    CommonImage, HrdCloudBannerVideo, Swiper, SwiperSlide, HotTipHeader, boardMainList, BoardMainListMobile},
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isMobile = computed(() => store.state.auth.isMobile);
    const boardMst = computed(() => getHotTipBoardMst(route.params.boardId));
    const boardCateList = computed(() => getHotTipBoardCateList(route.params.boardId));
    const showBoardList = ref(true);
    const activeBoardCateSn = computed(() => route.query.boardCateSn ? Number(route.query.boardCateSn) : 0);
    const hotTipBest = computed(() => route.query.postBadgeCdDcd == HotTipBoardBadgeCdDcd.BEST);
    const hotTipLike = computed(() => route.query.isLike == 'Y');
    const listTotalCount = ref(0);
    const banners = ref([]);

    const search = (k,v) => {
      let param = {...route.query};
      param[k] = v;
      param['pageNo'] = 1;
      router.push({query: param});
    }

    const getBanners = () => {
      banners.value = [];
      getBanerList({banerDivCdDcd: banerDivCdDcds.baner, banerExpsrLocCdDcd: banerExpsrLocCdDcds.hotTip[route.params.boardId]}, banners);
    }
    const goToBannerUrl = (item) => {
      if(item.banerUrl && item.banerUrl.length > 0){
        window.location.href = item.banerUrl;
      }
    }

    watch(() => activeBoardCateSn.value, () => {
      if(route.name === 'HotTipBoard'){
        showBoardList.value = false;
        setTimeout(() => {showBoardList.value = true}, 300);
      }
    })

    watch(() => [route.params.boardId, route.query], () => {
      if(route.name === 'HotTipBoard'){
        showBoardList.value = false;
        getBanners();
        setTimeout(() => {showBoardList.value = true}, 300)
      }
    })

    onMounted(getBanners)

    return {
      isMobile,
      boardMst,
      boardCateList,
      showBoardList,
      activeBoardCateSn,
      hotTipBest,
      hotTipLike,
      listTotalCount,
      banners,
      search,
      goToBannerUrl,
      hotTipManagerInfo,
      HotTipBoardBadgeCdDcd,
      modules: [Pagination, Autoplay],
    }
  }
}
</script>